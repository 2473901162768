import { useRouter } from 'next/router';
import i18next from '../../lib/i18n';

import {
  Avatar,
  cn,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Select,
  SelectItem,
} from '@nextui-org/react';
import cookies from 'js-cookie';
import { useSession } from 'next-auth/react';
import nodeAPI from '../../lib/nodeapi';

export default function LangSwitcher({ inControlMenu }) {
  const router = useRouter();
  const currentLang = cookies.get('i18next') ?? 'en-US';
  const { pathname, asPath, query } = useRouter();
  const { data: session } = useSession();

  const handleChangeLanguage = async (lang) => {
    await i18next.changeLanguage(lang).then(() => {
      router
        .push({ pathname, query }, asPath, {
          locale: lang,
        })
        .then(async () => {
          if ((await session?.secure) && router.isReady) {
            const setLang = await nodeAPI(
              'POST',
              session.secure,
              'user/setUserLanguage',
              { lang },
            );
          }
        });
    });
    i18next.dir(lang);
  };

  return (
    <>
      <LangSelect
        handleChangeLanguage={handleChangeLanguage}
        currentLang={currentLang}
        inControlMenu={inControlMenu}
      />
      {!inControlMenu && (
        <LangMenu
          handleChangeLanguage={handleChangeLanguage}
          currentLang={currentLang}
        />
      )}
    </>
  );
}

export const languages = [
  {
    key: 'en',
    code: 'en-US',
    flag: 'gb',
    country_name: 'English',
    dir: 'ltr',
    country_flag: 'https://flagcdn.com/gb.svg',
  },
  {
    key: 'ar',
    code: 'ar-SA',
    flag: 'sa',
    country_name: 'عربي',
    dir: 'rtl',
    country_flag: 'https://flagcdn.com/sa.svg',
  },
];

const getFlag = (code) => {
  return languages.find((l) => l.code === code).country_flag;
};

const LangSelect = ({ handleChangeLanguage, currentLang, inControlMenu }) => (
  <Select
    onSelectionChange={(e) => {
      handleChangeLanguage(e.currentKey);
    }}
    defaultSelectedKeys={[currentLang]}
    size="sm"
    className={cn('flex-1 lg:hidden', inControlMenu ? 'lg:flex' : '')}
    radius="full"
    aria-label="language-switcher"
  >
    {languages?.map((element) => (
      <SelectItem
        key={element.code}
        value={element.code}
        aria-label={element.code ?? 'language-feild'}
        // className="text-jacarta-50"
        startContent={
          <Avatar
            alt={element.country_name}
            className="w-6 h-6"
            src={element.country_flag}
          />
        }
      >
        {element.country_name}
      </SelectItem>
    ))}
  </Select>
);

const LangMenu = ({ handleChangeLanguage, currentLang }) => (
  <Dropdown
    classNames={{
      content: 'menu-gradient',
    }}
  >
    <DropdownTrigger className="hidden lg:flex">
      <Avatar
        className="h-7 w-7 hover:outline-accent-dark hover:outline-2 transition-all"
        as="button"
        src={getFlag(currentLang)}
      />
    </DropdownTrigger>
    <DropdownMenu
      aria-label="lang-switcher-menu"
      variant="flat"
      onAction={(code) => handleChangeLanguage(code)}
      itemClasses={{
        base: [
          'text-jacarta-800 dark:text-jacarta-50',
          'data-[hover=true]:bg-jacarta-100',
          'dark:data-[hover=true]:bg-jacarta-700',
        ],
      }}
    >
      {languages.map((element) => (
        <DropdownItem
          key={element.code}
          startContent={
            <Avatar
              alt={element.country_name}
              className="w-6 h-6"
              src={element.country_flag}
            />
          }
        >
          {element.country_name}
        </DropdownItem>
      ))}
    </DropdownMenu>
  </Dropdown>
);
