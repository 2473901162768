import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, cn, Image, Spinner } from '@nextui-org/react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useGeolocation } from '../../hooks/useGeolocation';
import { useYup } from '../../hooks/useYup';
import { amountChange } from '../../lib/helpers';
import nodeAPI from '../../lib/nodeapi';
import { _1D3ModalShow } from '../../redux/counterSlice';
import Gem from '../gem';
import useTamatemPaymentMethods from '../../hooks/query-hooks/useTamatemPaymentMethods';
import useCreateTamatemPayment from '@/hooks/mutation-hooks/useCreateTamatemPayment';
import useCreateEfawatercom from '@/hooks/mutation-hooks/useCreateEfawatercom';

const PaymentButtons = ({
  onPayment,
  amount,
  setAmount,
  method,
  setMethod,
  onClose,
  setStep,
  setFromDetailsBtn,
}) => {
  const { t } = useTranslation();
  const { data: session } = useSession();
  const dispatch = useDispatch();
  const countryCode = useGeolocation() || 'jo';

  const mode = useMemo(() => {
    const isDark = document.documentElement.classList.contains('dark');

    return isDark ? 'dark' : 'light';
  }, []);

  // will use these in the near future

  // const { tamatemPaymentMethods } = useTamatemPaymentMethods();
  // const { mutate } = useCreateTamatemPayment();

  const { mutate } = useCreateEfawatercom();

  const { data: paymentMethods, isLoading } = useQuery({
    queryKey: ['Deposit Methods', countryCode],
    queryFn: async () => {
      const { status, results } = await nodeAPI(
        'POST',
        session.secure,
        'finances/getWithdrawDepositMethods',
        { countryCode },
      );

      if (!status) return [];
      return results.filter((result) => result.type == 'DEPOSIT');
    },
  });

  const clientId =
    process.env.NODE_ENV === 'production'
      ? 'AVAfWRPTi4l0jctEL38tynkJ_w3GKAGvmgBoiWqfhdbunFrNYbCDBddLqGj2E-p1Ue7yABihyq1SKAd-'
      : 'AdCERWM0cWEUosx96UVrQWQOvtvKEZUoO8d9HoicDORBq_52shihT0T0enLqo-BHbhDidJjDSYRxUMtf';

  const selectedMethod = useMemo(() => {
    let selected = '';

    if (method == 'Bank')
      return paymentMethods?.find(
        ({ code: x }) => x == 'BANKJOETIHADDEPOSIT' || x == 'BANKINTLDEPOSIT',
      );

    if (method == 'CliQ') selected = 'CLIQJODEPOSIT';
    if (method == '1d3') selected = 'CC';
    if (method == 'Paypal') selected = 'PAYPAL';
    if (method == 'eFawateerkom') selected = 'EFAWATEERKOM';

    if (selected == '') return null;

    return paymentMethods?.find(({ code: x }) => x == selected);
  }, [paymentMethods, method]);

  const totalFees = useMemo(() => {
    const numGetAmount = Number(amount);
    const percentageFee = selectedMethod?.fee_percentage
      ? (numGetAmount * Number(selectedMethod?.fee_percentage)) / 100
      : 0;
    const totalFee = Number(selectedMethod?.fee_fixed) + percentageFee;

    return +totalFee;
  }, [amount, selectedMethod]);

  const createOrder = (data, actions) => {
    const depositAmount = (+amount + totalFees).toFixed(2).toString();

    return actions.order.create({
      purchase_units: [{ amount: { value: depositAmount } }],
    });
  };

  const onApprove = async (data) => {
    try {
      const response = await nodeAPI(
        'POST',
        session?.secure,
        'finances/PayPalCapture',
        {
          orderID: data.orderID,
          depositAmount: amount,
        },
      );

      onPayment({
        status: response.status === 'success' ? 'success' : 'fail',
        response: response,
        amount,
      });
    } catch (err) {
      onPayment({
        status: 'fail',
        response: err,
        amount,
      });
    }
  };

  const [_1D3initiating, set_1D3initiating] = useState(false);

  const { mutateAsync: init1D3 } = useMutation({
    mutationFn: async () => {
      const res = await nodeAPI(
        'POST',
        session?.secure,
        'finances/initiatePayment',
        {
          depositAmount: +amount + totalFees,
          depositCurrency: 'USD',
        },
      );

      return res.message;
    },
  });

  const open1D3 = async () => {
    setMethod('1d3');
    set_1D3initiating(true);
    const res = await init1D3();
    dispatch(_1D3ModalShow(res.url));

    setTimeout(() => {
      onClose();
      set_1D3initiating(false);
    }, 2500);
  };

  const hasPaymentMethod = (code) => {
    return paymentMethods?.find(({ code: x }) => x == code) != undefined;
  };

  const getFees = (code) => {
    const method = paymentMethods?.find(({ code: x }) => x == code);

    let fees = null;

    if (!method) return fees;

    if (method.fee_percentage > 0 && method.fee_fixed > 0)
      fees = `${method.fee_percentage}% + ${method.fee_fixed}$`;
    else if (method.fee_fixed > 0) fees = `${method.fee_fixed}$`;
    else if (method.fee_percentage > 0) fees = `${method.fee_percentage}%`;

    return fees;
  };

  if (isLoading || _1D3initiating)
    return (
      <div className="px-1 py-20 grid place-content-center">
        <Spinner />
      </div>
    );

  // will use these in the near future

  // if (tamatemPaymentMethods?.length) {
  //   return (
  //     <div className="flex flex-col gap-4 p-1">
  //       {tamatemPaymentMethods.map(({ name, image }) => (
  //         <Button
  //           variant="bordered"
  //           key={name}
  //           className="flex items-center justify-between h-14 border-jacarta-200 dark:border-jacarta-600 dark:hover:bg-jacarta-700"
  //           onPress={() =>
  //             mutate(
  //               { depositAmount: amount, paymentMethodName: name },
  //               { onSuccess: onClose },
  //             )
  //           }
  //         >
  //           <Image
  //             src={image[mode]}
  //             width={name === 'Fawry cash' ? 120 : 100}
  //             // height={height}
  //             alt="payment"
  //             className={name === 'Fawry cash' ? '!-mx-3' : ''}
  //             disableSkeleton
  //           />
  //         </Button>
  //       ))}
  //     </div>
  //   );
  // }

  if (paymentMethods?.length == 0)
    return (
      <div className="px-1 py-20 text-center">
        {t('We will support this country payment methods in the near future.')}
      </div>
    );

  return (
    <div className="flex flex-col gap-4 p-1">
      {!method && (
        <>
          {hasPaymentMethod('CC') &&
            [
              { path: `${mode}/visa.png`, width: 100, height: 50 },
              { path: `${mode}/apple_pay.png`, width: 80, height: 40 },
              { path: `${mode}/google_pay.png`, width: 80, height: 40 },
            ].map(({ path, width, height }) => (
              <Button
                variant="bordered"
                key={path}
                className="flex items-center justify-between h-14 border-jacarta-200 dark:border-jacarta-600 dark:hover:bg-jacarta-700"
                onPress={open1D3}
              >
                <Image
                  src={`/images/payments/${path}`}
                  width={width}
                  height={height}
                  alt="payment"
                  className="!-mx-2"
                  disableSkeleton
                />
                <span className="font-semibold text-jacarta-600 dark:text-jacarta-100">
                  {getFees('CC')}
                </span>
              </Button>
            ))}

          {hasPaymentMethod('PAYPAL') && (
            <Button
              variant="bordered"
              className="flex items-center justify-between h-14 border-jacarta-200 dark:border-jacarta-600 dark:hover:bg-jacarta-700"
              onPress={() => setMethod('Paypal')}
            >
              <div className="flex">
                <Image
                  src={`/images/payments/${mode}/paypal.png`}
                  width={100}
                  height={50}
                  alt="Paypal"
                  className="!-mx-2"
                  disableSkeleton
                />
                <Image
                  src={`/images/payments/${mode}/visa.png`}
                  width={100}
                  height={50}
                  alt="Paypal"
                  className="!-mx-2"
                  disableSkeleton
                />
              </div>
              <span className="font-semibold text-jacarta-600 dark:text-jacarta-100">
                {getFees('PAYPAL')}
              </span>
            </Button>
          )}
          {hasPaymentMethod('CLIQJODEPOSIT') && (
            <Button
              variant="bordered"
              className="flex items-center justify-between h-14 border-jacarta-200 dark:border-jacarta-600 dark:hover:bg-jacarta-700"
              onPress={() => setMethod('CliQ')}
            >
              <Image
                src={`/images/payments/${mode}/cliq.png`}
                width={80}
                height={40}
                alt="CliQ"
                className="!-mx-2"
                disableSkeleton
              />
              <span className="font-semibold text-jacarta-600 dark:text-jacarta-100">
                {getFees('CLIQJODEPOSIT')}
              </span>
            </Button>
          )}
          {hasPaymentMethod('BANKJOETIHADDEPOSIT') && (
            <Button
              variant="bordered"
              className="flex items-center justify-between h-14 border-jacarta-200 dark:border-jacarta-600 dark:hover:bg-jacarta-700"
              onPress={() => setMethod('Bank')}
            >
              <Image
                src={`/images/payments/${mode}/bank.png`}
                width={100}
                height={50}
                alt="CliQ"
                disableSkeleton
              />
              <span className="font-semibold text-jacarta-600 dark:text-jacarta-100">
                {getFees('BANKJOETIHADDEPOSIT')}
              </span>
            </Button>
          )}

          {/* {hasPaymentMethod('EFAWATEERKOM') && (
            <Button
              variant="bordered"
              className="flex items-center justify-between h-14 border-jacarta-200 dark:border-jacarta-600 dark:hover:bg-jacarta-700"
              onPress={() => {
                setMethod('eFawateerkom');
                mutate({ amount });
                setStep(3);
              }}
            >
              <Image
                src={`/images/payments/${mode}/eFawateerkom.png`}
                width={130}
                // height={70}
                alt="eFawateerkom"
                disableSkeleton
              />
            </Button>
          )} */}
        </>
      )}

      {method == 'Paypal' && (
        <div>
          <PayPalScriptProvider options={{ clientId, currency: 'USD' }}>
            <PayPalButtons
              style={{ color: 'gold' }}
              createOrder={createOrder}
              onApprove={onApprove}
            />
          </PayPalScriptProvider>

          <div className="flex justify-end mt-1">
            <Button
              variant="flat"
              onPress={() => setMethod(null)}
              radius="full"
              className="font-semibold"
            >
              {t('Cancel')}
            </Button>
          </div>
        </div>
      )}

      {(method == 'CliQ' || method == 'Bank' || method == 'eFawateerkom') && (
        <DepositForm
          paymentMethod={selectedMethod}
          amount={amount}
          setAmount={setAmount}
          totalFees={totalFees}
          fees={getFees(selectedMethod?.code)}
          setMethod={setMethod}
          method={method}
          onPayment={onPayment}
          setStep={setStep}
          setFromDetailsBtn={setFromDetailsBtn}
        />
      )}
    </div>
  );
};

const DepositForm = ({
  paymentMethod,
  amount,
  method,
  setAmount,
  totalFees,
  fees,
  setMethod,
  onPayment,
  setStep,
  setFromDetailsBtn,
}) => {
  const { data: session } = useSession();
  const router = useRouter();
  const { t } = useTranslation();
  const isInternationalBank = paymentMethod?.balance_method_id == 8;
  console.log(paymentMethod);
  const { data: paymentDetails } = useQuery({
    queryKey: ['Gamerg CliQ Details', paymentMethod?.balance_method_id],
    queryFn: async () =>
      await nodeAPI(
        'POST',
        session?.secure,
        'finances/gamergRecipientDetails ',
        {
          paymentMethod: paymentMethod?.balance_method_id,
        },
      ),
  });

  const { data: pendingPayments } = useQuery({
    queryKey: [
      'Pending Payments',
      {
        secure: session?.secure,
      },
    ],
    queryFn: async () =>
      await nodeAPI('POST', session?.secure, 'finances/checkIfPendingPayments'),
    initialData: { status: false },
  });

  const minAmount = !!paymentMethod?.is_minimum
    ? paymentMethod?.minimum_amount
    : 0;

  const isEn = router.locale == 'en-US';
  const { Yup } = useYup();
  const schema = Yup.object({
    senderFullName: Yup.string()
      .required()
      .label(isEn ? 'Full Name' : 'الاسم الكامل'),
    depositAmount: Yup.number()
      .positive()
      .required()
      .min(minAmount)
      .label(isEn ? 'Deposit Amount' : 'مبلغ الايداع'),
  });

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      depositAmount: isInternationalBank
        ? amount
        : (Math.round(amount * paymentMethod?.rate * 10) / 10).toFixed(1),
    },
  });

  const handleAmountChange = (value) => {
    const amountX = amountChange(value);
    if (amountX == null) return;

    const val = isInternationalBank
      ? amount
      : (Math.round((amountX / paymentMethod?.rate) * 10) / 10).toFixed(1);
    setAmount(val);
    setValue('depositAmount', amountX);
  };

  const paymentRequest = async (formValues) => {
    try {
      const response = await nodeAPI(
        'POST',
        session?.secure,
        'finances/localPayment',
        {
          paymentMethod: paymentMethod?.balance_method_id,
          senderFullName: formValues.senderFullName,
          depositAmount: amount * paymentMethod?.rate,
        },
      );

      onPayment({
        response,
        status: response.status ? 'pending' : 'fail',
        amount: formValues.depositAmount,
        currency: isInternationalBank ? 'USD' : 'JOD',
      });
    } catch (err) {
      onPayment({
        status: 'fail',
        response: err,
        amount: formValues.depositAmount,
        currency: isInternationalBank ? 'USD' : 'JOD',
      });
    }
  };

  return (
    <>
      {pendingPayments.status && (
        <div className="p-2 w-full border border-jacarta-100 bg-jacarta-50 dark:border-jacarta-500 dark:bg-jacarta-600 rounded text-center">
          {t('You have pending payments')}
        </div>
      )}

      {pendingPayments.status && method === 'CliQ' ? null : (
        <div className="text-center">
          {/* <p className="font-semibold">CliQ Transfer ALIAS</p> */}
          <span className="text-4xl font-bold text-accent">
            {paymentDetails?.message?.attribute_1}
          </span>
        </div>
      )}

      {(paymentMethod?.balance_method_id == 6 ||
        paymentMethod?.balance_method_id == 8) && (
        <div className="p-2 border border-jacarta-100 bg-jacarta-50 dark:border-jacarta-500 dark:bg-jacarta-600 grid grid-cols-[auto,auto] text-start rounded odd:[&>span]:font-semibold">
          <span>{t('Address')}</span>
          <span>{paymentDetails?.message?.attribute_2}</span>
          <span>{t('Account Name')}</span>
          <span>
            {router.locale == 'en-US'
              ? paymentDetails?.message?.attribute_3
              : paymentDetails?.message?.attribute_4}
          </span>
          <span>{t('Account Number')}</span>
          <span>{paymentDetails?.message?.attribute_5}</span>
          <span>{t('IBAN')}</span>
          <span>{paymentDetails?.message?.attribute_6}</span>
          <span>{t('Swift Code')}</span>
          <span>{paymentDetails?.message?.attribute_7}</span>
        </div>
      )}

      {pendingPayments.status && method === 'CliQ' ? null : (
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(paymentRequest)}
        >
          <div className="flex flex-col gap-1 text-start">
            <label htmlFor="fullname">{t('Full Name')}</label>
            <input
              type="text"
              id="fullname"
              {...register('senderFullName')}
              className="border-2  dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white px-3"
              placeholder={t('Full Name')}
            />
            <p className="text-red-500 text-sm">
              <ErrorMessage errors={errors} name="senderFullName" />
            </p>
          </div>
          <div className="flex items-end gap-3">
            <div className="flex flex-col gap-1 text-start flex-1">
              <label htmlFor="amount">
                {t(isInternationalBank ? 'Amount in USD' : 'Amount in JOD')}
              </label>
              <input
                type="number"
                step="0.01"
                id="amount"
                value={watch('depositAmount')}
                onChange={({ target: { value } }) => {
                  return handleAmountChange(value);
                }}
                placeholder={t(
                  isInternationalBank ? 'Amount in USD' : 'Amount in JOD',
                )}
                autoComplete="off"
                disabled
                className="border-2 dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 disabled:dark:bg-jacarta-800 disabled:bg-jacarta-50 dark:text-white px-3"
              />
              <p className="text-red-500 text-sm">
                <ErrorMessage errors={errors} name="depositAmount" />
              </p>
              <div className="flex items-center gap-1.5 bg-[#090C1D] text-white w-fit px-3 py-1.5 mx-auto rounded-full mt-1.5">
                <span>{t('You will receive')}</span>

                <div className="flex items-center translate-y-0.5">
                  <span
                    className={cn(
                      'font-display text-lg text-purple',
                      router.locale == 'ar-SA' && '-translate-y-0.5',
                    )}
                  >
                    {(+amount).toFixed(2)}
                  </span>
                  <span>
                    <Gem size={24} />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row flex-wrap items-center justify-between gap-y-2 gap-x-5 w-full">
            <div className="flex justify-start flex-1">
              {fees && (
                <span className="bg-jacarta-50 dark:bg-jacarta-600 font-semibold text-sm border border-jacarta-100 dark:border-jacarta-500 px-3 py-2 rounded">
                  {`${t('Fees')}: ${fees}`}
                </span>
              )}
            </div>
            <div className="flex items-center gap-1.5">
              <Button
                variant="flat"
                onPress={() => setMethod(null)}
                radius="full"
                className="font-semibold"
              >
                {t('Cancel')}
              </Button>
              <Button
                type="submit"
                variant="flat"
                className="bg-accent text-white font-semibold"
                radius="full"
              >
                {t('Confirm Deposit')}
              </Button>
            </div>
          </div>
        </form>
      )}
      {pendingPayments.status && method === 'CliQ' ? (
        <div className="flex-center gap-2">
          <Button
            variant="flat"
            onPress={() => setMethod(null)}
            radius="full"
            className="font-semibold"
          >
            {t('Cancel')}
          </Button>
          <Button
            variant="flat"
            className="bg-accent text-white font-semibold"
            onPress={() => {
              setFromDetailsBtn(true);
              setStep(3);
            }}
            radius="full"
          >
            {t('See Details')}
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default PaymentButtons;
