import useClaimDailyRewards from '@/hooks/mutation-hooks/useClaimDailyRewards';
import useDailyRewardProgress from '@/hooks/query-hooks/useDailyRewardProgress';
import {
  setCreditModal,
  setCreditSuccessModal,
  useAppDispatch,
  useAppSelector,
} from '@/redux/counterSlice';
import { modalStyle, primaryButton } from '@/theme/nextUI';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
} from '@nextui-org/react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { FaRegClock } from 'react-icons/fa6';
import { DayCard, DayLongCard } from '../layout/cards/DayCard';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
dayjs.extend(tz);

export const cardsArray = [
  {
    id: 1,
    amount: 5,
  },
  {
    id: 2,
    amount: 10,
  },
  {
    id: 3,
    amount: 5,
  },
  {
    id: 4,
    amount: 5,
  },
];

function CreditModal() {
  const dispatch = useAppDispatch();
  const { creditModalIsOpen } = useAppSelector((state) => state.counter);
  const closeModal = () => {
    dispatch(setCreditModal(false));
  };

  const { data, isPending, refetch } = useDailyRewardProgress();

  const { mutate: claimDailyRewards, isPending: isClaimPending } =
    useClaimDailyRewards({
      successCallback: () => {
        closeModal();
        refetch();
        dispatch(
          setCreditSuccessModal({
            isOpen: true,
            day: data?.day,
          }),
        );
      },
    });

  useEffect(() => {
    if (creditModalIsOpen) refetch();
  }, [creditModalIsOpen, refetch]);

  return (
    <Modal
      isOpen={creditModalIsOpen}
      size="md"
      placement="center"
      onClose={closeModal}
      classNames={{
        ...modalStyle,
        header: 'px-5 pb-0 border-none',
      }}
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          <h5 className="modal-title">{t('Daily Rewards')}</h5>
        </ModalHeader>
        <ModalBody className="flex flex-col items-center gap-2">
          {!isPending && !data ? null : (
            <CountdownTime nextReward={data?.nextReward!} />
          )}
          {isPending && !data && (
            <div className="flex items-center justify-center w-full h-32">
              <Spinner />
            </div>
          )}

          {!isPending && data && (
            <>
              <div className="w-full grid grid-cols-4 gap-2">
                {cardsArray.map((card) => (
                  <DayCard
                    key={card.id}
                    day={card.id}
                    amount={card.amount}
                    status={data.status}
                    currentDay={data.day}
                  />
                ))}
              </div>
              <DayLongCard
                day={5}
                amount={15}
                status={data.status}
                currentDay={data.day}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-center">
          <Button
            size="md"
            className={`${primaryButton} px-10 text-md`}
            radius="full"
            isDisabled={!data?.status}
            onClick={() => claimDailyRewards()}
            isLoading={isClaimPending}
          >
            {t('Claim Reward')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CreditModal;

const rendererCountdown = ({ hours, minutes, seconds }) => {
  return (
    <div className="flex items-center gap-2 w-full justify-center mb-3">
      <span className=" font-semibold">{t('Next Reward')}</span>
      <div className="rounded-full px-4 py-1 flex items-center gap-3 border-2 font-bold text-xs border-accent font-display tabular-nums">
        <FaRegClock fontSize={20} />
        <span className="ltr:translate-y-px text-medium">
          {hours}h {minutes}m {seconds}s
        </span>
      </div>
    </div>
  );
};

const CountdownTime = ({ nextReward }: { nextReward: Date }) => {
  const [targetEndTime, setTargetEndTime] = useState(nextReward);

  useEffect(() => {
    // Recalculate the target end time to 12:00 AM at the start of each day
    setTargetEndTime(nextReward);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Countdown
      date={targetEndTime}
      renderer={(props) => rendererCountdown(props)}
      onComplete={() => {
        // When countdown completes, restart for the next day
        setTargetEndTime(nextReward);
      }}
    />
  );
};