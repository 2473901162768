import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  cn,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@nextui-org/react';
import { t } from 'i18next';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import useTracker, { TrakerTypes } from '../../hooks/useTraker';

import MenuButtonTrigger from './MenuButtonTrigger';
import SocialMediaIcons from '@/components/socialMediaIcons';
import CurrencySwitcher from './CurrencySwitcher';
import LangSwitcher from './LangSwitcher';
import { useControlPanelStore } from '@/store/useControlPanelStore';
import { RiCloseFill, RiMenu5Fill } from 'react-icons/ri';

export default function UnAuthMobileMenu() {
  const { isOpen, setIsOpen } = useControlPanelStore();
  const { status } = useSession();
  const isAuthenticated = status === 'authenticated';

  return (
    <Popover isOpen={isOpen} onOpenChange={setIsOpen} shouldBlockScroll>
      <PopoverTrigger>
        {isAuthenticated ? null : (
          <Button
            radius="full"
            isIconOnly
            variant="faded"
            className={cn(
              'font-semibold hover:opacity-100 hover:bg-accent dark:hover:bg-accent  hover:text-white dark:hover:text-white shadow-sm xl:hidden',
              isOpen
                ? 'bg-accent dark:bg-accent text-white'
                : 'bg-white dark:bg-transparent border-accent text-accent dark:text-accent  ',
              'menu-step-1', // this is for steps tour
            )}
            aria-label="nav-menu-icon"
          >
            {isOpen ? (
              <RiCloseFill fontSize={'20px'} />
            ) : (
              <RiMenu5Fill fontSize={'22px'} />
            )}
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent
        aria-label="Static Actions"
        className={'menu-gradient min-w-[90vw] md:min-w-[350px]  '}
      >
        <MobileMenu />
      </PopoverContent>
    </Popover>
  );
}

const MobileMenu = () => {
  const router = useRouter();
  const { asPath, push } = router;
  const trackTournamentPage = useTracker({
    key: TrakerTypes.TournamentPageVisited,
  });
  const { setIsOpen } = useControlPanelStore();

  return (
    <Card shadow="none" className="w-full border-none bg-transparent">
      <CardHeader className="justify-between flex-col lg:hidden gap-2">
        <div className="flex-center gap-4 w-full">
          <CurrencySwitcher inControlMenu />
          <LangSwitcher inControlMenu />
        </div>
      </CardHeader>
      <CardFooter
        className={`border-t-1 border-jacarta-300 flex items-start lg:items-center flex-col gap-1 $`}
      >
        <Button
          variant="light"
          className={` ${menuButtonClassnames} ${asPath === '/' ? 'text-accent' : ''}`}
          onClick={() => {
            setIsOpen(false);
            push('/');
          }}
        >
          {t('Home')}
        </Button>

        <Button
          variant="light"
          className={` ${menuButtonClassnames} ${asPath === '/tournaments' ? 'text-accent' : ''} `}
          onClick={() => {
            trackTournamentPage();
            setIsOpen(false);
            push('/tournaments');
          }}
        >
          {t('Tournaments')}
        </Button>

        <Button
          variant="light"
          className={`${menuButtonClassnames} ${asPath === '/leaderboard' ? 'text-accent' : ''}`}
          onClick={() => {
            setIsOpen(false);
            push('/leaderboard');
          }}
        >
          {t('Leaderboard')}
        </Button>
        <Button
          variant="light"
          className={`${menuButtonClassnames} ${asPath === '/contact' ? 'text-accent' : ''}`}
          onClick={() => {
            setIsOpen(false);
            push('/contact');
          }}
        >
          {t('Help_Center')}
        </Button>

        <SocialMediaIcons className="mt-3 justify-center" />
      </CardFooter>
    </Card>
  );
};

const menuButtonClassnames = `text-jacarta-700 dark:text-jacarta-50 font-semibold hover:bg-transparent dark:hover:bg-transparent px-2 text-start inline lg:hidden data-[hover=true]:bg-transparent h-7`;
