import useHasLiveTours from '@/hooks/useHasLiveTours';
import useTracker, { TrakerTypes } from '@/hooks/useTraker';
import { depositModalShow, useAppDispatch } from '@/redux/counterSlice';
import { useControlPanelStore } from '@/store/useControlPanelStore';
import { Card, CardBody, CardFooter, Image } from '@nextui-org/react';
import { t } from 'i18next';
import { useSession } from 'next-auth/react';
import Link from 'next/link';

// type = 'navbar' | 'sidebar'
export const NavMenuMainActoins = ({ isAuthenticated, user, type }) => {
  const dispatch = useAppDispatch();
  const { status } = useSession();
  const { hasLive } = useHasLiveTours();

  const trackProfile = useTracker({ key: TrakerTypes.trackProfile });
  const trackGPartner = useTracker({ key: TrakerTypes.GPartner });
  const trackDeposit = useTracker({ key: TrakerTypes.DepositFromMenu });
  const trackWithdraw = useTracker({ key: TrakerTypes.WithdrawFromMenu });
  const trackTournamentPage = useTracker({
    key: TrakerTypes.TournamentPageVisited,
  });

  const { setIsOpen } = useControlPanelStore();

  const navActions = [
    {
      name: t('Deposit'),
      path: null,
      icon: '/images/navIcons/deposit-icon.png',
      tracker: trackDeposit,
      requiredAuth: true,
      actionFn: () => {
        dispatch(depositModalShow());
        setIsOpen(false);
      },
    },
    {
      name: t('Play Now'),
      path:
        status === 'authenticated'
          ? `/dashboard${hasLive ? '?t=live' : ''}`
          : '/tournaments',
      icon: '/images/navIcons/play-icon.png',
      tracker: trackTournamentPage,
      navItemValue: 35,
      isPlayButton: true,
    },
    {
      name: t('Withdraw'),
      path: '/dashboard/withdraw',
      icon: '/images/navIcons/withdraw-icon.png',
      tracker: trackWithdraw,
      navItemValue: 35,
      requiredAuth: true,
    },
    {
      name: t('GG Store'),
      path: `/dashboard/store`,
      icon: '/images/navIcons/store.png',
      tracker: trackProfile,
      requiredAuth: true,
      navItemValue: 32,
    },
    {
      name: t('Partner'),
      path: '/dashboard/invite',
      icon: '/images/navIcons/share-icon.png',
      tracker: trackGPartner,
      requiredAuth: true,
      navItemValue: 33,
    },
    {
      name: t('Leaderboard'),
      path: '/leaderboard',
      icon: '/images/navIcons/leadboard-icon.png',
      navItemValue: 55,
    },
  ];

  return (
    <div
      className={`w-full grid gap-3 ${
        isAuthenticated ? 'grid-cols-3' : 'grid-cols-2'
      } justify-items-center`}
    >
      {navActions.map((action) => {
        if (!isAuthenticated && action.requiredAuth) return null;
        return (
          <ActionCard
            key={action.name}
            icon={action.icon}
            name={action.name}
            path={action.path}
            actionFn={action.actionFn}
            hasLive={hasLive}
            type={type}
            isPlayButton={action.isPlayButton}
            clickHandler={() => {
              if (action.tracker) {
                action.tracker();
              }
              setIsOpen(false);
            }}
          />
        );
      })}
    </div>
  );
};

function ActionCard({
  name,
  path,
  icon,
  clickHandler,
  hasLive,
  actionFn,
  type,
  isPlayButton,
}) {
  const CARD = (
    <Card className="bg-transparent shadow-none max-w-[85px] rounded-none">
      <CardBody
        className={`flex-center rounded-xl p-0 shadow-md shadow-jacarta-300 dark:shadow-jacarta-700 ${type === 'navbar' ? 'h-[75px]' : 'h-[70px]'} transition-all  ${path === '/dashboard' && hasLive ? 'border-3 animate-border-color' : path === '/tournaments' ? 'border-3 border-red' : ''} ${isPlayButton ? 'bg-purple hover:bg-purple-dark' : 'bg-accent hover:bg-accent-dark'}`}
      >
        <Image
          src={icon}
          alt={name}
          className="w-[70%] mx-auto"
          disableSkeleton
        />
      </CardBody>
      <CardFooter className="flex-center text-xs p-0 mt-1 rounded-none">
        <span className="text-black dark:text-jacarta-100 ">{name}</span>
      </CardFooter>
    </Card>
  );

  if (path)
    return (
      <Link href={path} className="cursor-pointer" onClick={clickHandler}>
        {CARD}
      </Link>
    );

  return <button onClick={actionFn}>{CARD}</button>;
}
