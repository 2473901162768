import nodeAPI from '@/lib/nodeapi';
import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

interface IClaimDailyRewards {
  day: number;
  status: boolean;
  nextReward: Date;
}

function useDailyRewardProgress() {
  const { data: session, status } = useSession();

  const query = useQuery({
    queryKey: ['credits-daily-progress', session?.secure],
    queryFn: async () => {
      const response: IClaimDailyRewards = await nodeAPI(
        'GET',
        session?.secure,
        'dailyReward/progress',
      );
      return response;
    },
    enabled: status === 'authenticated',
  });

  return query;
}

export default useDailyRewardProgress;
