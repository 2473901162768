import {
  Button,
  cn,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@nextui-org/react';
import { useSession } from 'next-auth/react';
import useBreakpoint from '@/hooks/useBreakpoint';
import useCheckDisclamer from '@/hooks/useCheckDisclamer';
import { useControlPanelStore } from '@/store/useControlPanelStore';
import CtrMenu from './CtrMenu';
import LoginButton from './LoginButton';
import { RiCloseFill, RiMenu5Fill } from 'react-icons/ri';

export default function LoginButtonWithMenu() {
  const { isOpen, setIsOpen } = useControlPanelStore();
  const { status } = useSession();
  const { isLess: isLessLargeScreen } = useBreakpoint('xl');
  const { isLess: isLessMd } = useBreakpoint('sm');
  const { isData } = useCheckDisclamer();

  const isAuthenticated = status === 'authenticated' && !isData?.banned;

  return (
    <>
      <LoginButton
        classNames={isLessMd ? 'w-fit text-xs' : ''}
        size={isLessMd ? 'sm' : 'md'}
      />
      {isAuthenticated && isLessLargeScreen && (
        <Popover isOpen={isOpen} onOpenChange={setIsOpen} shouldBlockScroll>
          <PopoverTrigger>
            <Button
              radius="full"
              isIconOnly
              variant="faded"
              className={cn(
                'font-semibold hover:opacity-100 hover:bg-accent dark:hover:bg-accent  hover:text-white dark:hover:text-white shadow-sm xl:hidden',
                isOpen
                  ? 'bg-accent dark:bg-accent text-white'
                  : 'bg-white dark:bg-transparent border-accent text-accent dark:text-accent  ',
                'menu-step-1', // this is for steps tour
              )}
              aria-label="nav-menu-icon"
            >
              {isOpen ? (
                <RiCloseFill fontSize={'20px'} />
              ) : (
                <RiMenu5Fill fontSize={'22px'} />
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            aria-label="Static Actions"
            className={'menu-gradient min-w-[350px] xl:min-w-[300px] '}
          >
            <CtrMenu isAuthenticated={isAuthenticated} />
          </PopoverContent>
        </Popover>
      )}
    </>
  );
}
