import { formatNumber } from '@/lib/helpers';
import {
  setDashboardTab,
  setTournamentModalAction,
  tournamentModalShow,
  useAppDispatch,
} from '@/redux/counterSlice';
import { useTournamentStore } from '@/store/useTournamentStore';
import { primaryButton } from '@/theme/nextUI';
import {
  PrizeStructure,
  Sponser,
  Template,
  Tournament,
  TournamentType,
} from '@/types/types';
import { Button, cn, Image, Tab, Tabs } from '@nextui-org/react';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGamepad, FaStopwatch } from 'react-icons/fa6';
import { HiUsers } from 'react-icons/hi';
import { MdGamepad } from 'react-icons/md';
import TournamentHeader from './tournamentHeader';
import useBreakpoint from '@/hooks/useBreakpoint';
import { BiChevronRight } from 'react-icons/bi';
import { useLiveStore } from '@/store/useLiveStore';
import { useSession } from 'next-auth/react';

interface TournamentCardProps {
  tournament: Tournament;
  joinedList?: number[];
  isFull?: boolean;
  removeActions?: boolean;
  removePills?: boolean;
  removeHeader?: boolean;
  removeAD?: boolean;
  removeDetails?: boolean;
  removePrize?: boolean;
  className?: string;
  horizontal?: boolean;
}

const TournamentCard: FC<TournamentCardProps> = ({
  tournament,
  joinedList = [],
  isFull = false,
  removeActions = false,
  removePills = false,
  removeHeader = false,
  removeAD = false,
  removeDetails = false,
  removePrize = false,
  horizontal = false,
  className,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const setTournament = useTournamentStore((state) => state.setTournament);

  const { entry_fee, template, durationTextEN, durationTextAR } = tournament;

  const tournamentType: TournamentType = template.is_credits
    ? 'credits'
    : template.big_tournament
      ? 'big'
      : 'elite';

  const { isLess: isLessMd } = useBreakpoint('sm');
  const isBig = horizontal && !isLessMd;

  return (
    <div
      className={cn(
        'relative bg-jacarta-700 border border-jacarta-700 text-white rounded-xl p-3 transition-shadow hover:shadow-lg overflow-hidden items-center',
        isBig && 'flex gap-3',
        className,
      )}
    >
      <Image
        src={template.img_url}
        alt="Tournament Image"
        classNames={{
          img: 'absolute top-0 left-0 z-0 w-full h-full object-cover select-none pointer-events-none',
        }}
        disableSkeleton
        loading="lazy"
      />

      <div className="flex flex-col relative gap-2">
        {!removeHeader && (
          <TournamentHeader
            template={template}
            tournamentType={tournamentType}
          />
        )}

        {!isBig && !removePrize && (
          <Prize
            template={template}
            tournamentType={tournamentType}
            horizontal={isBig}
          />
        )}

        {!removeAD && <AD sponsers={template.sponsers} />}

        {!removePills && (
          <div className="grid grid-cols-2 items-center justify-center gap-1.5 text-center flex-wrap">
            {[
              {
                icon: <MdGamepad size={20} />,
                value:
                  router.locale === 'en-US'
                    ? template.game_mode_details.game_mode_en
                    : template.game_mode_details.game_mode_ar,
              },
              {
                icon: <FaStopwatch size={20} />,
                value:
                  router.locale === 'en-US' ? durationTextEN : durationTextAR,
              },
              {
                icon: <HiUsers size={20} />,
                value: template.max_per_bracket,
              },
              {
                icon: <FaGamepad size={20} />,
                value:
                  router.locale === 'en-US'
                    ? template.firstGamesEN
                    : template.firstGamesAR,
              },
            ].map(({ icon, value }, i) => (
              <div
                key={i}
                className="flex items-center gap-1 bg-jacarta-100 w-full text-black rounded-full px-2 py-1 text-sm whitespace-nowrap font-display"
              >
                <span className="shrink-0">{icon}</span>
                <div className="truncate" title={value + ''}>
                  {value}
                </div>
              </div>
            ))}
          </div>
        )}

        {!removeDetails && (
          <TournamentDetails
            firstGames={
              router.locale === 'en-US'
                ? template.firstGamesEN
                : template.firstGamesAR
            }
            prizeStructure={template.prize_structure}
            howToImgUrl={template.how_to_img_url}
            tournamentType={tournamentType}
          />
        )}

        {!removeActions && (
          <footer className="flex flex-col gap-0.5 mt-0.5">
            {!isBig && <hr className="text-accent" />}
            <div className="flex items-end justify-between gap-2">
              <div
                className={cn(
                  'flex text-center px-3 py-1.5 border rounded-full bg-black/50 backdrop-blur-[1px]',
                  template.is_credits ? 'border-accent' : 'border-purple',
                )}
              >
                <div className="font-display">
                  {t('Ticket')}

                  <span className="mx-2">
                    {tournamentType == 'credits'
                      ? formatNumber(entry_fee)
                      : entry_fee}
                  </span>
                </div>
                <Image
                  src={
                    tournamentType == 'credits'
                      ? '/images/tournament/GG credits.png'
                      : '/images/gem/gem.png'
                  }
                  width={tournamentType == 'credits' ? 24 : 26}
                  height={tournamentType == 'credits' ? 24 : 26}
                  classNames={{ wrapper: 'shrink-0' }}
                  className={cn(tournamentType != 'credits' && '-m-0.5 -mb-0')}
                  alt="Currency"
                />
              </div>

              <div className="flex gap-2 [&>button]:w-full mt-1">
                <TournamentActions
                  id={tournament.id}
                  isFull={isFull}
                  joinedList={joinedList}
                  onSelect={() => {
                    setTournament(tournament);
                  }}
                />
              </div>
            </div>
          </footer>
        )}
      </div>

      <div className="flex flex-col flex-1">
        {isBig && !removePrize && (
          <Prize
            template={template}
            tournamentType={tournamentType}
            horizontal={isBig}
          />
        )}
      </div>
    </div>
  );
};

const TournamentActions = ({ id, isFull, joinedList, onSelect }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { status } = useSession();
  const { setTournamentId, setBracketId } = useLiveStore();

  const goLive = () => {
    setTournamentId(id);
    setBracketId(joinedList[0]);
    dispatch(setDashboardTab('live'));
    router.push('/dashboard?t=live');
  };

  const hasJoined = joinedList.length > 0;

  // Joined not Playing
  if (hasJoined)
    return (
      <Button
        className={cn(primaryButton, 'bg-green')}
        radius="full"
        onPress={goLive}
        endContent={
          <BiChevronRight className="-mx-2 rtl:rotate-180" size={26} />
        }
      >
        {t('Continue')}
      </Button>
    );

  // Not Joined And Full
  if (!hasJoined && isFull)
    return (
      <Button
        className={cn(primaryButton, 'bg-disabled text-black')}
        radius="full"
        disableAnimation
      >
        {t('Full')}
      </Button>
    );

  // IDLE
  if (!hasJoined)
    return (
      <Button
        className={cn(primaryButton, 'bg-accent', 'step-3')}
        radius="full"
        onPress={() => {
          if (status == 'unauthenticated') {
            router.push('/login');
            return;
          }
          onSelect();
          dispatch(tournamentModalShow());
          dispatch(
            setTournamentModalAction({
              name: 'join',
              removeCloseButton: true,
              noIcon: true,
            }),
          );
        }}
        endContent={
          <BiChevronRight className="-mx-2 rtl:rotate-180" size={26} />
        }
      >
        {t('Play Now')}
      </Button>
    );
};

const TournamentDetails = ({
  firstGames,
  prizeStructure,
  howToImgUrl,
  tournamentType,
}: {
  firstGames: string;
  prizeStructure: PrizeStructure;
  howToImgUrl: string;
  tournamentType: TournamentType;
}) => {
  const { t } = useTranslation();

  const groupedPrizeStructure = prizeStructure
    .flat()
    .sort((a, b) => +b.prize - +a.prize)
    .reduce((acc, item) => {
      const key = item.prize;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

  const getPlace = (ps) => {
    let place = ps[0].minFormatted;

    if (ps[0].minFormatted != ps[0].maxFormatted)
      place += ' - ' + ps[0].maxFormatted;

    if (ps.length > 1) {
      place += ' - ' + ps.sort((a, b) => +a.min - +b.min).at(-1).maxFormatted;
    }

    return place;
  };

  return (
    <Tabs
      aria-label="Details"
      variant="light"
      color="primary"
      classNames={{
        tab: 'border-2 border-jacarta-400',
        cursor: 'rounded-md',
        tabContent: 'text-white font-medium font-display',
        panel: 'py-0',
      }}
      fullWidth
    >
      <Tab key="prizes" title={t('Prizes')}>
        <div>
          {Object.entries(groupedPrizeStructure).map(([prize, ps]) => (
            <div className="flex justify-between items-center" key={prize}>
              <span>{`${getPlace(ps)} ${t('Place')}`}</span>
              <span
                className="flex items-center gap-1 font-display !font-medium"
                dir="ltr"
              >
                {tournamentType == 'credits' ? (
                  <Image
                    src="/images/tournament/GG credits.png"
                    className="h-5 min-w-5"
                    alt="GG Credit"
                  />
                ) : (
                  '$'
                )}
                {tournamentType == 'credits' ? formatNumber(prize) : prize}
              </span>
            </div>
          ))}
        </div>
      </Tab>
      <Tab key="scoringSystem" title={t('Scoring System')}>
        <p>{t('Scoring_System_Deets')}</p>

        <Image src={howToImgUrl} alt="How to paly" className="mt-2" />
      </Tab>
      <Tab key="rules" title={t('Rules')}>
        <div>
          <ul className="space-y-2">
            <li>{t('Rules_1')}</li>
            <li>{`${t('Rules_2.1')} ${firstGames} ${t('Rules_2.2')}`}</li>
          </ul>
        </div>
      </Tab>
    </Tabs>
  );
};

const Prize = ({
  template,
  tournamentType,
  horizontal,
}: {
  template: Template;
  tournamentType: TournamentType;
  horizontal: boolean;
}) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <div
      className={cn(
        'flex *:flex-1 gap-4 items-center justify-center text-white p-3 text-center rounded relative',
        tournamentType == 'credits' ? 'bg-accent/60' : 'bg-purple/60',
        horizontal && 'flex-col',
      )}
    >
      <div
        className={cn(
          'flex justify-center items-center h-full',
          horizontal && 'hidden',
        )}
      >
        {tournamentType == 'credits' && (
          <Image
            src="/images/tournament/GG credits.png"
            className="h-12 min-w-12"
            alt="GG Credit"
          />
        )}
      </div>
      <div className="flex flex-col items-center">
        <span
          className={cn(
            'font-bold font-display',
            +template.total_prize < 1000 ? 'text-5xl' : 'text-4xl',
          )}
        >
          {tournamentType != 'credits' && '$'}
          {formatNumber(+template.total_prize)}
        </span>
      </div>
      <div className="flex flex-col items-center font-display">
        <span
          className={cn(
            'text-xl uppercase',
            router.locale == 'en-US' && 'tracking-widest',
          )}
        >
          {t('Prize')}
        </span>
        <span className="font-light whitespace-nowrap text-sm">
          {t('Per Bracket')}
        </span>
      </div>
    </div>
  );
};

const AD = ({ sponsers }: { sponsers: Sponser | undefined }) => {
  if (!sponsers?.sponser_name) return null;

  return (
    sponsers.sponser_name && (
      <>
        <div className="grid grid-cols-3 gap-2 *:rounded *:select-none [&>div>img]:rounded-lg">
          {sponsers.img_one && (
            <Image removeWrapper src={sponsers.img_one} alt="Sponser" />
          )}
          {sponsers.img_two && (
            <Image removeWrapper src={sponsers.img_two} alt="Sponser" />
          )}
          {sponsers.img_three && (
            <Image removeWrapper src={sponsers.img_three} alt="Sponser" />
          )}
        </div>
      </>
    )
  );
};

export default TournamentCard;
