export default async function nodeAPI(
  method,
  secure,
  query,
  variables = {},
  multipart = false,
) {
  let dev = false;
  // dev = true;
  const API_URL = !dev
    ? process.env.NEXT_PUBLIC_NODE_API
    : 'http://127.0.0.1:3010/api/v2/';

  const headers = { secure };

  if (multipart) {
    delete headers['Content-Type'];
  } else {
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
  }

  const res = await fetch(API_URL + query, {
    method: method,
    headers: headers,
    ...(method == 'POST' && {
      body: multipart
        ? variables.body
        : new URLSearchParams(variables).toString(),
    }),
  });

  const json = await res.json();
  if (json.errors) {
    // console.error(json.errors);
    throw new Error('Failed to fetch API');
  }

  return json;
}
