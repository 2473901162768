import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { getLocalTimeZone, today } from '@internationalized/date';
import {
  Button,
  DatePicker,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
} from '@nextui-org/react';
import { useMutation } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useUserCountryAndDate from '../../hooks/useUserCountryAndDate.ts';
import { useYup } from '../../hooks/useYup';
import { DOBSet, setDOBModalHide } from '../../redux/counterSlice';
import { modalStyle, primaryButton } from '../../theme/nextUI';
import nodeAPI from './../../lib/nodeapi';

const DOBModal = () => {
  const { setDOBModal } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: session } = useSession();
  const { defaultCountryAndDate } = useUserCountryAndDate();
  const { Yup } = useYup();

  const now = today(getLocalTimeZone());
  const maxDate = now.subtract({ years: 18 });

  const isOver18 = ({ day, month, year }) => {
    const dt = now.set({ day, month, year });
    return maxDate.compare(dt) > 0;
  };

  const schema = Yup.object({
    dateOfBirth: Yup.object({
      day: Yup.number().label(t('Day')),
      month: Yup.number().label(t('Month')),
      year: Yup.number().label(t('Year')),
    })
      .required()
      .test('is-over-18', t('Over_18'), isOver18)
      .label(t('Date_Of_Birth')),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const { mutate: onSubmit, isPending } = useMutation({
    mutationFn: async (data) => {
      if (!session?.secure) return;

      const {
        country_id,
        dateOfBirth: { day, month, year },
      } = data;

      await nodeAPI('POST', session.secure, 'profile/setUserDOB', {
        day_id: day,
        month_id: month,
        year_id: year,
      });

      dispatch(DOBSet());
      closeModal();
    },
  });

  useEffect(() => {
    if (!defaultCountryAndDate || !setDOBModal) return;
    setValue('dateOfBirth', {
      day: defaultCountryAndDate.day,
      month: defaultCountryAndDate.month,
      year: defaultCountryAndDate.year,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDOBModal]);

  const closeModal = async () => {
    dispatch(setDOBModalHide());
  };

  return (
    <div>
      <Modal isOpen={setDOBModal} onClose={closeModal} classNames={modalStyle}>
        <ModalContent as={'form'} onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <p className="text-md font-display text-center mt-5 text-lg">
              {t('Date_Eligibility_Disclaimer')}
            </p>

            <>
              <div className="flex flex-col gap-1">
                <DatePicker
                  {...register('dateOfBirth')}
                  label={t('Date_Of_Birth')}
                  labelPlacement="outside"
                  granularity="day"
                  defaultValue={defaultCountryAndDate}
                  onChange={(e) => {
                    setValue('dateOfBirth', {
                      day: e?.day,
                      month: e?.month,
                      year: e?.year,
                    });
                  }}
                  variant="bordered"
                />
                <span className="text-sm text-red-500">
                  <ErrorMessage name="dateOfBirth" errors={errors} />
                </span>
              </div>
            </>
          </ModalBody>
          <ModalFooter className="justify-center">
            <Button
              type="submit"
              className={primaryButton}
              isLoading={isPending}
              radius="full"
            >
              {t('confirm_information')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default DOBModal;
