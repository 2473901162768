import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from '@nextui-org/react';
import { t } from 'i18next';
import cookies from 'js-cookie';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setCreditModal } from '../../redux/counterSlice';
import { useBalanceStore } from '../../store/useBalance';
import CurrencySwitcher from './CurrencySwitcher';
import LangSwitcher from './LangSwitcher';
import useUserProfile from '../../hooks/useUserProfile';
import { UserCard } from '../layout/cards/UserCard';
import { GemsCard } from '../layout/cards/GemsCard';
import { PrizeCard } from '../layout/cards/PrizeCard';
import { GGCard } from '../layout/cards/GGCard';
import { SidebarNavlist } from '../layout/cards/SidebarNavlist';
import { NavMenuMainActoins } from './NavMenuMainActoins';
import { useControlPanelStore } from '../../store/useControlPanelStore';
import SocialMediaIcons from '@/components/socialMediaIcons';

export default function CtrMenu({ isAuthenticated }) {
  const dispatch = useDispatch();
  const { data: user } = useUserProfile();
  const { balance } = useBalanceStore();
  const currencyNotUSD =
    cookies.get('currency') && cookies.get('currency') !== 'USD';
  const { setIsOpen } = useControlPanelStore();

  return (
    <Card shadow="none" className="w-full border-none bg-transparent">
      <CardHeader className="justify-between flex-col xl:hidden gap-2">
        {isAuthenticated ? <UserCard /> : null}
      </CardHeader>
      <CardBody className="gap-4">
        <div className="flex-center gap-4 w-full">
          <CurrencySwitcher inControlMenu />
          <LangSwitcher inControlMenu />
        </div>
        {isAuthenticated ? (
          <>
            <div className="flex gap-3">
              <GemsCard balance={balance?.depositBalance?.toFixed(2)} />
              <PrizeCard
                balance={balance?.prizeUserBalance?.toFixed(2)}
                notUSD={currencyNotUSD}
              />
            </div>
            <div className="flex gap-3 ">
              <GGCard balance={balance?.creditBalance} />
              <Button
                className="bg-GG-blue text-white rounded-md font-semibold flex-1 max-w-[48%] self-end text-sm"
                onClick={() => {
                  dispatch(setCreditModal(true));
                  setIsOpen(false);
                }}
              >
                {t('Claim')}
              </Button>
            </div>
            {/* <Button
              size="md"
              radius="full"
              className="bg-red hover:bg-accent-dark w-full text-lg font-semibold text-white transition-all leading-none"
              onClick={() => {
                trackConnectGame();
                setIsOpen(false);
                router.push('/dashboard/account-settings?t=connect');
              }}
            >
              {t('Connect Games')}
            </Button> */}
          </>
        ) : null}
        <NavMenuMainActoins
          user={user}
          isAuthenticated={isAuthenticated}
          type={'navbar'}
        />
      </CardBody>
      <CardFooter
        className={`border-t-1 border-jacarta-300 flex items-start xl:items-center flex-col gap-1 ${isAuthenticated ? 'xl:pt-0' : ''}`}
      >
        <SidebarNavlist />
        <SocialMediaIcons className="mt-3 justify-center" />
      </CardFooter>
    </Card>
  );
}

// function BalanceCard({ balance, title, notUSD, withGem }) {
//   return (
//     <div className="flex items-center gap-2 flex-col">
//       <p
//         className={`text-md font-bold ${withGem ? 'text-purple' : 'text-accent'}`}
//       >
//         {title}
//       </p>
//       <div
//         className={`rounded-full text-center  py-1 border ${
//           withGem ? 'border-purple px-6' : 'border-accent px-4'
//         }`}
//       >
//         <p className={`text-lg font-bold ${withGem ? '' : 'text-accent'}`}>
//           {withGem ? (
//             <Gem text={balance?.toFixed(2)} />
//           ) : (
//             `${balance?.toFixed(2)} ${t('USD')}`
//           )}
//         </p>
//       </div>
//       {notUSD ? (
//         <div className="bg-jacarta-100 text-black py-1 px-2 flex-center rounded-full self-end -mt-1 w-full">
//           <p className="text-sm font-semibold tracking-tight">
//             ~ <CurrencyField amount={balance} />
//           </p>
//         </div>
//       ) : null}
//     </div>
//   );
// }
