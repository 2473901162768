import { setCreditModal, useAppDispatch } from '@/redux/counterSlice';
import { Button } from '@nextui-org/react';

import useUserProfile from '@/hooks/useUserProfile';
import { useBalanceStore } from '@/store/useBalance';
import { t } from 'i18next';
import cookies from 'js-cookie';
import DiscordButton from '../../navbar/DiscordButton';

import { NavMenuMainActoins } from '@/components/navbar/NavMenuMainActoins';
import { GemsCard } from '../cards/GemsCard';
import { GGCard } from '../cards/GGCard';
import { PrizeCard } from '../cards/PrizeCard';
import { SidebarNavlist } from '../cards/SidebarNavlist';
import { UserCard } from '../cards/UserCard';

function Sidebar() {
  const { balance } = useBalanceStore();
  const currencyNotUSD =
    cookies.get('currency') && cookies.get('currency') !== 'USD';
  const { data: userProfileData } = useUserProfile();
  const dispatch = useAppDispatch();

  return (
    <aside className="xl:sticky top-[64px] sticky-0 h-[calc(100vh-64px)] w-72 hidden xl:flex flex-col shadow-lg z-40 py-5 px-4 border-e-1 dark:border-jacarta-700 border-jacarta-100 bg-[#f7f5ff] dark:bg-jacarta-800">
      <UserCard userProfileData={userProfileData} />
      <div className="px-2 flex-col flex flex-1">
        <div className=" flex-1">
          <div className="menu-step-1">
            <div className="flex gap-5 my-3">
              <GemsCard balance={balance?.depositBalance?.toFixed(2)} />
              <PrizeCard
                balance={balance?.prizeUserBalance?.toFixed(2)}
                notUSD={currencyNotUSD}
              />
            </div>

            <div className="flex gap-5 mb-5">
              <GGCard balance={balance?.creditBalance} />
              <Button
                className="bg-GG-blue text-white rounded-md font-semibold flex-1 max-w-[110px] self-end text-sm"
                onClick={() => dispatch(setCreditModal(true))}
              >
                {t('Claim')}
              </Button>
            </div>

            <NavMenuMainActoins isAuthenticated={true} user={userProfileData} />
          </div>
        </div>
        <DiscordButton />
        <div className="mt-3 ps-4">
          <SidebarNavlist />
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
