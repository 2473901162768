import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  cn,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
} from '@nextui-org/react';
import { useMutation } from '@tanstack/react-query';
import { OTPInput, REGEXP_ONLY_DIGITS } from 'input-otp';
import { useSession } from 'next-auth/react';
import { useState } from 'react';
import Countdown from 'react-countdown';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useYup } from '@/hooks/useYup';
import {
  AccountSet,
  setRefetchProfile,
  setupAccountModalHide,
  useAppDispatch,
  useAppSelector,
} from '@/redux/counterSlice';
import { modalStyle } from '@/theme/nextUI';
import nodeAPI from '@/lib/nodeapi';
import { inputClasses } from '../shared/FormInput';
import PhoneInput from '../PhoneInput';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import useAccountSet from '@/hooks/useAccountSet';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';

const SetupAccountModal = () => {
  const { setupAccountModal } = useAppSelector((state) => state.counter);
  const dispatch = useAppDispatch();
  const { data: session } = useSession();
  const { t } = useTranslation();
  const router = useRouter();
  const { Yup } = useYup();

  const [step, setStep] = useState(1);
  const [code, setCode] = useState('');
  const [isIncorrectCode, setIsIncorrectCode] = useState(false);
  const [retryinterval, setRetryInterval] = useState(1);
  const [countDown, setCountDown] = useState<number | undefined>();

  const { data: userDetails } = useAccountSet();

  const {
    mutateAsync: validateDisplayName,
    isPending: isValidatingDisplayName,
  } = useMutation({
    mutationFn: async (username) => {
      try {
        Yup.string()
          .min(5)
          .max(20)
          .matches(
            /^[a-zA-Z0-9]+$/,
            t(
              'Only letters and numbers are allowed. No special characters or spaces.',
            ) as string,
          )
          .validateSync(username);
      } catch (err) {
        return false;
      }

      const { unique } = await nodeAPI(
        'POST',
        session?.secure,
        'user/CheckDisplayName',
        { username },
      );

      return unique;
    },
  });

  const { mutateAsync: validateEmail, isPending: isValidatingEmail } =
    useMutation({
      mutationFn: async (email) => {
        try {
          Yup.string().required().email().validateSync(email);
        } catch (err) {
          return false;
        }

        const { unique } = await nodeAPI(
          'POST',
          session?.secure,
          'user/CheckEmailUnique',
          { email },
        );

        return unique;
      },
    });

  const { mutateAsync: validatePhone } = useMutation({
    mutationFn: async () => {
      try {
        const { phone, countryCode } = getValues();

        if (!countryCode || !phone) return false;

        const pn = parsePhoneNumber('+' + phone, {
          defaultCountry: countryCode as CountryCode,
        });

        return pn.isValid();
      } catch (error) {
        return false;
      }
    },
  });

  const schema = Yup.object({
    displayName: Yup.string()
      .min(5)
      .max(20)
      .required()
      .test(
        'validDisplayName',
        t('The display name is already taken') as string,
        validateDisplayName as any,
      )
      .label(t('Display name')),
    ...(userDetails?.provider == 'otp'
      ? {
          email: Yup.string()
            .email()
            .required()
            .test(
              'validEmail',
              t('The email address is already used') as string,
              validateEmail as any,
            )
            .label(t('Email')),
        }
      : {
          email: Yup.string(),
        }),
    ...(userDetails?.provider != 'otp' && !userDetails?.hasPhone
      ? {
          phone: Yup.string()
            .required()
            .test(
              'validEmail',
              t('Phone number is invalied') as string,
              validatePhone as any,
            ),
        }
      : {
          phone: Yup.string(),
        }),
    countryCode: Yup.string(),
  });

  const { handleSubmit, control, reset, getValues, setValue, setError } =
    useForm({
      resolver: yupResolver(schema),
      values: {
        displayName: '',
        email: '',
        phone: '',
        countryCode: 'JO',
      },
    });

  const { mutate: onSubmit, isPending: isSendingVerificationEmail } =
    useMutation({
      mutationFn: async () => {
        try {
          const { displayName: username, email, phone } = getValues();

          setCountDown(new Date().getTime() + 1000 * 60 * retryinterval);

          if (userDetails?.provider != 'otp') {
            const response = await nodeAPI(
              'POST',
              session?.secure,
              'user/sendSMSVerification',
              {
                user_phone: phone,
              },
            );

            if (!response.status) {
              if (response.message == 'ALREADY_LINKED') {
                setError('phone', {
                  message: t(
                    'This number is already linked to another account',
                  ) as string,
                });
              } else toast.error(t('Something went wrong!'));

              return;
            }
          } else {
            const response = await nodeAPI(
              'POST',
              session?.secure,
              'user/sendEmailVerification',
              {
                username,
                email,
              },
            );

            if (!response.status) {
              toast.error(t('Something went wrong!'));

              return;
            }
          }

          setStep(2);
        } catch (error) {
          console.log(error);
        }
      },
    });

  const {
    mutate: validateSecurityCode,
    isPending: isValidatingCode,
    data: securityCodeMessage,
  } = useMutation({
    mutationFn: async () => {
      if (code.length < 6) return;

      const { email, displayName: username, phone, countryCode } = getValues();

      const { status, verified } = await nodeAPI(
        'POST',
        session?.secure,
        'user/validateVerificationByCode',
        { security_code: code, email, username, phone, countryCode },
      );

      if (!status) {
        setIsIncorrectCode(true);
        setTimeout(() => {
          setIsIncorrectCode(false);
        }, 2500);

        return;
      }

      setStep(3);
      dispatch(AccountSet());
      dispatch(setRefetchProfile());
      router.push('/u/' + username);
      return status && verified
        ? t('Your account has already been verified')
        : t('Account has been successfully verified');
    },
  });

  const renderer = ({ seconds, minutes, completed }) => {
    if (completed)
      return (
        <Link
          className="cursor-pointer"
          onPress={() => {
            setRetryInterval((v) => (v == 1 ? 3 : v == 3 ? 5 : v));
            onSubmit();
          }}
        >
          {t('Resend Code')}
        </Link>
      );

    return (
      <p>
        {t('Please wait')}
        <span className="ordinal slashed-zero tabular-nums ">
          {minutes > 0
            ? ` ${minutes.toString().padStart(2, '0')} ${t('minutes')} ${t('and')}`
            : ''}
          {` ${seconds.toString().padStart(2, '0') + ' ' + t('seconds')} `}
        </span>
        {t('before requesting another code.')}
      </p>
    );
  };

  async function closeModalReset() {
    setCode('');
    setStep(1);
    reset({ displayName: '', email: '', countryCode: 'JO', phone: '' });
    dispatch(setupAccountModalHide());
  }

  return (
    <Modal
      isOpen={setupAccountModal}
      onClose={closeModalReset}
      classNames={modalStyle}
    >
      <ModalContent
        as="form"
        onSubmit={handleSubmit(onSubmit as any, (e) => console.log(e))}
      >
        <ModalHeader>
          <h5 className="modal-title">{t('verify_account')}</h5>
        </ModalHeader>
        <ModalBody className="gap-3">
          {step == 1 && (
            <>
              <Controller
                control={control}
                name="displayName"
                render={({ field, fieldState: { error, invalid } }) => (
                  <>
                    <Input
                      variant="bordered"
                      value={field.value}
                      onChange={field.onChange}
                      type="text"
                      id="displayName"
                      placeholder={t('display_name_placeholder') as string}
                      classNames={inputClasses}
                      label={t('Display name')}
                      labelPlacement="outside"
                      isInvalid={invalid}
                      errorMessage={error?.message}
                    />
                  </>
                )}
              />

              {userDetails?.provider == 'otp' && (
                <Controller
                  control={control}
                  name="email"
                  render={({ field, fieldState: { error, invalid } }) => (
                    <>
                      <Input
                        variant="bordered"
                        value={field.value as any}
                        onChange={field.onChange}
                        type="text"
                        id="email"
                        placeholder={t('email_address_placeholder') as string}
                        classNames={inputClasses}
                        label={t('Email')}
                        labelPlacement="outside"
                        isInvalid={invalid}
                        errorMessage={error?.message}
                      />
                    </>
                  )}
                />
              )}

              {userDetails?.provider != 'otp' && !userDetails?.hasPhone && (
                <Controller
                  control={control}
                  name="phone"
                  render={({ field, fieldState: { error, invalid } }) => (
                    <>
                      <PhoneInput
                        phone={field.value as any}
                        setPhone={field.onChange}
                        phoneCountry={getValues('countryCode') ?? 'JO'}
                        setPhoneCountry={(v) => setValue('countryCode', v)}
                        label="Whatsapp Number"
                        placeholder="Enter Whatsapp number"
                        isInvalid={invalid}
                        errorMessage={error?.message}
                      />
                    </>
                  )}
                />
              )}
            </>
          )}

          {step == 2 && (
            <div>
              <p className="mb-5">
                {t(`We've sent a security code to`)}
                <b>{` ${getValues(userDetails?.provider == 'otp' ? 'email' : 'phone') || ''}, `}</b>
                {t(`Please enter it below to complete your account setup.`)}
              </p>

              <div className="flex flex-col items-center justify-center gap-2">
                <OTPInput
                  autoFocus
                  value={code}
                  onChange={(v) => setCode(v)}
                  maxLength={6}
                  pattern={REGEXP_ONLY_DIGITS}
                  className="!w-full"
                  textAlign="center"
                  inputMode="numeric"
                  render={({ slots }) => (
                    <div className="flex items-center justify-center" dir="ltr">
                      {slots.slice(0, 6).map(({ isActive, char }, idx) => (
                        <div
                          key={idx}
                          className={cn(
                            'relative w-10 h-14 text-[2rem]',
                            'flex items-center justify-center',
                            'transition-all duration-300',
                            'border-jacarta-200 dark:border-jacarta-600 border-y border-e first:border-s first:rounded-s-md last:rounded-e-md',
                            'group-hover:border-ring-accent group-focus-within:border-ring-accent ring-inset',
                            { 'ring-2 ring-accent': isActive },
                            {
                              'border-red-500 ring-red-500 bg-red-50/75':
                                isIncorrectCode,
                            },
                          )}
                        >
                          {char !== null && <div>{char}</div>}
                        </div>
                      ))}
                    </div>
                  )}
                  onComplete={(code) => {
                    validateSecurityCode(code);
                  }}
                />

                <Countdown
                  key={countDown}
                  date={countDown}
                  renderer={renderer}
                />
              </div>
            </div>
          )}

          {step == 3 && (
            <div className="flex flex-col items-center justify-center gap-2">
              <Image
                src="/images/payments/status/success.png"
                width={120}
                alt="Status"
              />
              <h2 className="font-display text-lg">{t('Account Verified')}</h2>
              <p className="text-sm">{securityCodeMessage}</p>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="items-center justify-center">
          {step == 1 && (
            <Button
              type="submit"
              className="bg-accent font-semibold text-white rounded-full disabled:bg-disabled disabled:text-black"
              isLoading={isSendingVerificationEmail}
              isDisabled={isValidatingDisplayName || isValidatingEmail}
            >
              {t('Continue')}
            </Button>
          )}

          {step == 2 && (
            <>
              <Button
                variant="light"
                className="font-semibold rounded-full"
                onPress={() => setStep(1)}
              >
                {t('Back')}
              </Button>
              <Button
                type="button"
                className="bg-accent font-semibold text-white rounded-full disabled:bg-disabled disabled:text-black"
                onPress={() => validateSecurityCode()}
                isLoading={isValidatingCode}
                isDisabled={code.length < 6}
              >
                {t('Verify')}
              </Button>
            </>
          )}

          {step == 3 && (
            <>
              <Button
                type="button"
                className="bg-accent font-semibold text-white rounded-full disabled:bg-disabled disabled:text-black"
                onPress={closeModalReset}
                isLoading={isValidatingCode}
              >
                {t('Close')}
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SetupAccountModal;
