import nodeAPI from '@/lib/nodeapi';
import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import cookies from 'js-cookie';

const useSetTimezone = () => {
  const { data: session, status } = useSession();

  useQuery({
    queryKey: ['Timezone', session?.secure],
    queryFn: async () => {
      const localTimezone = cookies.get('GG_TZ');
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (localTimezone == tz) return tz;

      await nodeAPI('POST', session?.secure, 'user/setUserTimezone', {
        timezone: tz,
      });

      cookies.set('GG_TZ', tz, { expires: 365 });
      return tz;
    },
    enabled: status == 'authenticated',
  });
};

export default useSetTimezone;
