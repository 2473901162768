import useUserProfile from '@/hooks/useUserProfile.js';
import { setupAccountModalShow, useAppDispatch } from '@/redux/counterSlice';
import { Button, ButtonProps, cn } from '@nextui-org/react';
import { useSession } from 'next-auth/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const VerifyAccount: FC<ButtonProps> = ({ className, ...rest }) => {
  const { status } = useSession();
  const dispatch = useAppDispatch();
  const { data: userProfile, isLoading } = useUserProfile();
  const { t } = useTranslation();
  

  if (status != 'authenticated' || isLoading || userProfile?.username)
    return null;
  
    return (
      <Button
        className={cn('bg-accent font-display text-center', className)}
        onPress={() => dispatch(setupAccountModalShow())}
        {...rest}
      >
        {t('Verify your account')}
      </Button>
    );
};

export default VerifyAccount;
