import Currency from '@/components/Currency';
import TournamentHeader from '@/components/play/tournamentHeader';
import { formatNumber } from '@/lib/helpers';
import { LiveTournament } from '@/types/types';
import { cn, Image } from '@nextui-org/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuClock } from 'react-icons/lu';
import { MdCheckCircleOutline } from 'react-icons/md';
import LiveCountdown from './liveCountdown';
import useLivePhase from '@/hooks/useLivePhase';

const LiveCard = ({
  tournament,
  onSelect,
}: {
  tournament: LiveTournament;
  onSelect?: () => void;
}) => {
  const { t } = useTranslation();

  const {
    liveTimes,
    template,
    total_prize = 0,
    entry_fee = 0,
    bracket_id,
  } = tournament;

  const { currPhase, adjustPhase, currRemainingTime } = useLivePhase(liveTimes);

  const phaseDetails = useMemo(() => {
    const phases = [
      {
        label: t('Playing'),
        image: (
          <Image
            src="/images/icons/loading2.gif"
            width={20}
            height={20}
            alt="Game Icon"
            className="shrink-0"
          />
        ),
      },
      {
        label: t('Scoring'),
        image: (
          <Image
            src="/images/icons/accent-loader.png"
            className="animate-spin shrink-0"
            style={{ animationDuration: '5s' }}
            width={20}
            height={20}
            alt="Game Icon"
          />
        ),
      },
      {
        label: t('Final Score'),
        image: <MdCheckCircleOutline size={20} className="text-accent" />,
      },
    ];

    return phases[currPhase - 1];
  }, [currPhase, t]);

  if (!template) return null;

  return (
    <div
      className="p-3 bg-jacarta-700 rounded-lg text-sm shadow-medium relative w-full h-auto flex justify-stretch"
      onClick={() => onSelect?.()}
    >
      <Image
        src={template?.img_url}
        alt="Tournament Image"
        classNames={{
          img: 'absolute top-0 left-0 z-0 w-full h-full object-cover select-none pointer-events-none',
        }}
        disableSkeleton
        loading="lazy"
      />

      <div className="flex gap-5 relative z-[1] w-full">
        <div className="flex-col items-center space-y-2 font-display w-full">
          <div className="flex items-center justify-between flex-1 gap-2">
            <TournamentHeader template={template} hidePrize />
            <span className="px-2 py-1 text-xs bg-green-success text-black rounded-full shrink-0 font-display">
              ID: {bracket_id}
            </span>
          </div>

          <div className="flex gap-2">
            <div className="flex flex-col justify-center gap-2 flex-1">
              <div className="rounded-full bg-jacarta-100 px-2 py-1 flex items-center justify-center gap-1 flex-grow">
                {phaseDetails.image}
                <span
                  className={cn(
                    'text-accent',
                    phaseDetails.label == t('Playing') && 'text-red-500',
                  )}
                >
                  {phaseDetails.label}
                </span>
              </div>
              <div className="rounded-full bg-jacarta-100 px-2 py-1 flex items-center justify-center gap-1 flex-grow text-black">
                <LuClock size={20} className="shrink-0" />
                {liveTimes && bracket_id && (
                  <LiveCountdown
                    key={`${bracket_id}x${currPhase}`}
                    time={currRemainingTime}
                    completionText="Already Finished"
                    onComplete={() => adjustPhase()}
                  />
                )}
              </div>
            </div>

            <div
              className={cn(
                'flex items-center justify-center text-white text-center rounded min-w-24 py-2',
                +entry_fee > 0 ? 'bg-accent/60' : 'bg-purple/60',
              )}
            >
              <Currency isCredits={!!template?.is_credits} className="text-lg">
                {formatNumber(+total_prize)}
              </Currency>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveCard;
