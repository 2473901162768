import { Image } from '@nextui-org/react';
import { CardTooltip } from './CardTooltip';
import { t } from 'i18next';
import { depositModalShow, useAppDispatch } from '@/redux/counterSlice';
import { useControlPanelStore } from '@/store/useControlPanelStore';

interface Props {
  balance: number;
}
export const GemsCard = ({ balance }: Props) => {
  const dispatch = useAppDispatch();
  const { setIsOpen } = useControlPanelStore();

  return (
    <div className="flex gap-2 flex-col flex-1 ">
      <div className="flex items-center justify-between">
        <p className={`text-sm font-semibold text-purple`}>{t('Gems')}</p>
        <CardTooltip text={t('Gems are used to join elite tournaments')} />
      </div>
      <div
        className={`rounded-md text-center  py-2 border text-purple border-purple px-4 w-full relative cursor-pointer`}
        onClick={() => {
          dispatch(depositModalShow());
          setIsOpen(false);
        }}
      >
        <p className={`text-md font-semibold text-purple`}>
          {balance ?? '0.00'}{' '}
        </p>
        <div className="self-end absolute top-2 end-1">
          <Image
            disableSkeleton
            src="/images/gem/gem.png"
            className="w-6 h-6"
            alt="Gem"
          />
        </div>
      </div>
    </div>
  );
};
