import { useAppSelector } from '@/redux/counterSlice';
import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import nodeAPI from '../lib/nodeapi';

interface AccountSet {
  hasCountry: boolean;
  hasDob: boolean;
  hasPhone: boolean;
  isSet: boolean;
  status: boolean;
  provider: 'google' | 'facebook' | 'otp';
}

const useAccountSet = () => {
  const { data: session, status } = useSession();
  const { checkAccountSet } = useAppSelector((state) => state.counter);

  return useQuery<AccountSet>({
    queryKey: ['Account Set', session?.secure, checkAccountSet],
    queryFn: async () =>
      await nodeAPI('POST', session?.secure, 'auth/accountSet'),
    enabled: status == 'authenticated',
  });
};

export default useAccountSet;
